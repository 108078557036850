@use '../../styles/variables';

.headerRow {
  height: 72px;
  border-bottom: 1px solid variables.$Grey;
}

.hours {
  display: flex;
  align-items: center;
}

.phone {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.headerContainer {
  background: white;
  top: 0;
  position: fixed;
  display: block;
  @media only screen and (min-width: 769px) {
    display: none;
  }
  @media only screen and (max-width: 310px) {
    font-size: 13px;
  }
}
