@use '../../styles/variables';

.footerContainer {
  bottom: 0;
  position: fixed;
  height: 70px;
  background: variables.$primaryPurple;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 769px) {
    display: none;
  }
  z-index: 10;
}

.text {
  color: white;
  text-align: center;
}
