.itemContainer {
  display: flex;
  justify-content: space-between;
}

.desktopHeader {
  margin-bottom: 80px;
  @media only screen and (max-width: 768px) {
    display: none;
    margin-bottom: 0px;
  }
}
