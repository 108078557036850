@use '../../styles/variables';

.header {
  font-size: 32px;
}

.input {
  width: 100%;
  height: 38px;
  border: 1px solid variables.$GreyDark;
  padding-left: 10px;
  color: variables.$primaryPurple;
  &:focus {
    outline: none;
  }
}

.inputDesktop {
  width: 100%;
  height: 38px;
  border: 1px solid variables.$GreyDark;
  padding-left: 10px;
  color: variables.$primaryPurple;
  &:focus {
    outline: none;
  }
}

.area {
  width: 100%;
  height: 38px;
  border: 1px solid variables.$GreyDark;
  padding-left: 10px;
  color: variables.$primaryPurple;
  &:focus {
    outline: none;
  }
  height: 76px;
  padding-top: 8px;
  resize: none;
}

.areaDesktop {
  width: 100%;
  height: 78px;
  border: 1px solid variables.$GreyDark;
  padding-left: 10px;
  color: variables.$primaryPurple;
  &:focus {
    outline: none;
  }
  padding-top: 8px;
  grid-area: area;
  resize: none;
}

.submitButton {
  width: 100%;
  background: variables.$primaryPurple;
  border: none;
  height: 38px;
  color: white;
  grid-area: submit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileFormItems {
  display: block;
  @media only screen and (min-width: 769px) {
    display: none;
  }
}

.desktopFormItems {
  display: none;
  @media only screen and (min-width: 769px) {
    display: grid;
  }
  grid-gap: 12px;
  grid-template-columns: 300px 300px;
  grid-template-rows: 40px 40px 80px 40px;
  grid-template-areas:
    'name phone'
    'email company'
    'area area'
    'submit .';
}

.name {
  grid-area: name;
}

.email {
  grid-area: email;
}

.phone {
  grid-area: phone;
}

.company {
  grid-area: company;
}

.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #8785a2;
  width: 32px;
  height: 32px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mobileSubmitTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktopLoaderContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.submittedContainer {
  border: 1px solid #dfdfdf;
  height: 236px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    height: 317px;
  }
}

.submittedHeading {
  font-size: 22px;
}
